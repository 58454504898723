import React from "react";
import SEO from "../components/seo";
import Wrap from "../components/wrap";

import { graphql } from "gatsby";

import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import MdxLink from "../components/mdxLink";

function VolunteersPage({
  data: {
    intro,
    turtle,
    turtleSignup,
    sumatra,
    sumatraSignup,
    sumatraCollect,
    sumatraCollectSignup,
  },
}) {
  return (
    <>
      <SEO
        title="Vrijwilliger worden bij Wildlife Watchdogs"
        keywords={[`Vrijwilliger`, `Indonesië`, `Sumatra`, `Turtle Warrior`]}
      />
      <Wrap>
        <MDXProvider
          components={{
            h1: (props) => <h1 {...props} className="c-h1" />,
            h2: (props) => (
              <h2 {...props} className="c-h3 text-jungle-200 c-font-hand" />
            ),
            p: (props) => <p {...props} className="c-p-lg max-w-6xl" />,
            a: MdxLink,
          }}
        >
          <MDXRenderer>{intro.edges[0].node.childMdx.body}</MDXRenderer>
        </MDXProvider>
      </Wrap>

      <div className="bg-jungle-100 bg-edge bg-edge--be-100">
        <Wrap className="md:grid my-0 gap-lg">
          <div className="col-span-8">
            <MDXProvider
              components={{
                h2: (props) => <h2 {...props} className="c-h2" />,
                h3: (props) => (
                  <h3 {...props} className="c-h4 text-jungle-300 c-font-hand" />
                ),
                p: (props) => <p {...props} className="c-p" />,
              }}
            >
              <MDXRenderer>{turtle.edges[0].node.childMdx.body}</MDXRenderer>
            </MDXProvider>
          </div>

          <div className="col-span-4 bg-jungle-400 text-white rounded p-6">
            <MDXProvider
              components={{
                h3: (props) => <h3 {...props} className="c-h3" />,
                p: (props) => <p {...props} className="c-p" />,
                a: (props) => (
                  <MdxLink {...props} className="c-button c-button--white" />
                ),
              }}
            >
              <MDXRenderer>
                {turtleSignup.edges[0].node.childMdx.body}
              </MDXRenderer>
            </MDXProvider>
          </div>
        </Wrap>
      </div>

      <div className="bg-white">
        <Wrap className="md:grid my-0 gap-lg">
          <div className="col-span-8">
            <MDXProvider
              components={{
                h2: (props) => <h2 {...props} className="c-h2" />,
                h3: (props) => (
                  <h3 {...props} className="c-h4 text-jungle-300 c-font-hand" />
                ),
                p: (props) => <p {...props} className="c-p" />,
              }}
            >
              <MDXRenderer>{sumatra.edges[0].node.childMdx.body}</MDXRenderer>
            </MDXProvider>
          </div>

          <div className="col-span-4 bg-jungle-400 text-white rounded p-6">
            <MDXProvider
              components={{
                h3: (props) => <h3 {...props} className="c-h3" />,
                p: (props) => <p {...props} className="c-p" />,
                a: (props) => (
                  <MdxLink {...props} className="c-button c-button--white" />
                ),
              }}
            >
              <MDXRenderer>
                {sumatraSignup.edges[0].node.childMdx.body}
              </MDXRenderer>
            </MDXProvider>
          </div>
        </Wrap>
      </div>

      <div className="bg-jungle-100 bg-edge bg-edge--te-100">
        <Wrap className="md:grid my-0 gap-lg">
          <div className="col-span-8">
            <MDXProvider
              components={{
                h2: (props) => <h2 {...props} className="c-h2" />,
                h3: (props) => (
                  <h3 {...props} className="c-h4 text-jungle-300 c-font-hand" />
                ),
                h4: (props) => <h4 {...props} className="c-h4" />,
                p: (props) => <p {...props} className="c-p" />,
                ul: (props) => (
                  <ul {...props} className="c-p list-inside list-disc" />
                ),
              }}
            >
              <MDXRenderer>
                {sumatraCollect.edges[0].node.childMdx.body}
              </MDXRenderer>
            </MDXProvider>
          </div>

          <div className="col-span-4 bg-jungle-400 text-white rounded p-6">
            <MDXProvider
              components={{
                h3: (props) => <h3 {...props} className="c-h3" />,
                p: (props) => <p {...props} className="c-p" />,
                a: (props) => (
                  <MdxLink {...props} className="c-button c-button--white" />
                ),
              }}
            >
              <MDXRenderer>
                {sumatraCollectSignup.edges[0].node.childMdx.body}
              </MDXRenderer>
            </MDXProvider>
          </div>
        </Wrap>
      </div>
    </>
  );
}

export default VolunteersPage;

export const query = graphql`
  query IndexVolunteersQuery($locale: String!) {
    intro: allFile(
      filter: {
        sourceInstanceName: { eq: "mdx-blocks" }
        relativeDirectory: { eq: "volunteers/intro" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      edges {
        node {
          childMdx {
            body
          }
        }
      }
    }

    sumatra: allFile(
      filter: {
        sourceInstanceName: { eq: "mdx-blocks" }
        relativeDirectory: { eq: "volunteers/sumatra" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      edges {
        node {
          childMdx {
            body
          }
        }
      }
    }

    sumatraSignup: allFile(
      filter: {
        sourceInstanceName: { eq: "mdx-blocks" }
        relativeDirectory: { eq: "volunteers/sumatra-signup" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      edges {
        node {
          childMdx {
            body
          }
        }
      }
    }

    sumatraCollect: allFile(
      filter: {
        sourceInstanceName: { eq: "mdx-blocks" }
        relativeDirectory: { eq: "volunteers/sumatra-collect" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      edges {
        node {
          childMdx {
            body
          }
        }
      }
    }

    sumatraCollectSignup: allFile(
      filter: {
        sourceInstanceName: { eq: "mdx-blocks" }
        relativeDirectory: { eq: "volunteers/sumatra-collect-signup" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      edges {
        node {
          childMdx {
            body
          }
        }
      }
    }

    turtle: allFile(
      filter: {
        sourceInstanceName: { eq: "mdx-blocks" }
        relativeDirectory: { eq: "volunteers/turtle" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      edges {
        node {
          childMdx {
            body
          }
        }
      }
    }

    turtleSignup: allFile(
      filter: {
        sourceInstanceName: { eq: "mdx-blocks" }
        relativeDirectory: { eq: "volunteers/turtle-signup" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      edges {
        node {
          childMdx {
            body
          }
        }
      }
    }
  }
`;
